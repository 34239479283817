
.btn-small {
    font-size: 12px;
    width: 100%;
}

.btn-custom-link{
    font-size: 12px;
    line-height: 14px !important;
    text-decoration: none;
    cursor: pointer;
    display: block;
}

.btn-add-sheet {
    height: 40px;
    width: 40px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.btn-config-sheet {
    height: 40px;
    width: 40px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 8px;
}

.btn-fake-link {
  
        background: none!important;
        border: none;
        padding: 0!important;
        font-weight: 500;
        /*input has OS specific font-family*/
        
        text-decoration: underline;
        cursor: pointer;
      
}

.btn-accordion-items {
    padding: 8px;
    border-radius: 0px !important;
}



.btn-black-transparent {
    background-color: rgba($color: black, $alpha: 0.1);
    color: black;
    font-weight: 500;
    border-radius: 16px;
    padding: 4px 8px;
    text-decoration: none;

    &:hover {
        background-color: rgba($color: black, $alpha: 0.2);
        color: black;

    }
    span {
        margin-left: 6px;
        margin-right: 6px;
    }
}