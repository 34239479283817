.navbar__main-content {
    display: flex;
    padding: 16px;
}


.navbar__link {
    color: white;
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
    margin-right: 8px;

    &:hover {
        color: darken($color: white, $amount: 20);
    }
}

.me-0 {
    margin-right: 0;
}